import { editorReady as editorReadyImpl } from './editor-ready'
import { getAppManifest as getAppManifestImpl } from './get-app-manifest'
import { onEvent as onEventImpl } from './on-event'
import { generateExportedApi } from '../core/api-generator'
import { editorAppMetaData } from './editor-app'
import { handleAction as handleActionImpl } from './handle-action'
import { AppExposedApis, EditorPlatformApp } from '@wix/platform-editor-sdk'

export const editorReady: EditorPlatformApp['editorReady'] = (
  editorSDK,
  appDefinitionId,
  payload,
) => {
  return editorReadyImpl(editorSDK, appDefinitionId, payload)
}

export const getAppManifest: EditorPlatformApp['getAppManifest'] = (options, editorSDK) => {
  return getAppManifestImpl(options, editorSDK)
}

export const onEvent: EditorPlatformApp['onEvent'] = (eventPayload, eventType) => {
  return onEventImpl(eventPayload, eventType)
}

export const handleAction: EditorPlatformApp['handleAction'] = (payload, editorSDK) => {
  return handleActionImpl(payload, editorSDK)
}

export const exports: EditorPlatformApp['exports'] = (editorSDK: EditorSDK): AppExposedApis => ({
  public: generateExportedApi(editorAppMetaData),
  editor: {
    canAddWidget: async ({ widgetId }) => {
      const controllerType = widgetId as ControllerType
      if (controllerType === 'registrationForm') {
        return true
      } else {
        const api = await editorAppMetaData.getCoreApi()
        return !(await api.addForm.hasExceededFormsCount({ hasNewFormRef: false }))
      }
    },
  },
})
